import { useEffect, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Cart from './pages/Cart/Cart';
import Order from './pages/Order/Order';
import Payment from './pages/Payment/Payment';
import Product from './pages/Product/Product';
import Store from './pages/Store/Store';
import Welcome from './pages/Welcome/Welcome';
import { fetchAllData, setTheme, Theme } from './redux/appManager';
import { useAppDispatch, useAppSelector } from './reduxHook';
import './scss/app.scss';
import useTelegram from './utils/telegram';

function App() {
	const { tg } = useTelegram();
	const { cart, theme } = useAppSelector(state => state.appManager);
	const dispatch = useAppDispatch();
	const isMounted = useRef(false);

	useEffect(() => {
		tg.ready();
		tg.expand();
		tg.enableClosingConfirmation();
		tg.disableVerticalSwipes();
		dispatch(setTheme(tg.colorScheme === 'light' ? Theme.LIGHT : Theme.DARK));
	}, [dispatch, tg]);

	useEffect(() => {
		dispatch(fetchAllData());
	}, [dispatch]);

	useEffect(() => {
		if (isMounted.current) {
			cart.length &&
				localStorage.setItem(
					'cart',
					JSON.stringify(
						cart.map(cartItem => ({
							productId: cartItem.product.id,
							quantity: cartItem.quantity,
						})),
					),
				);
		}
		isMounted.current = true;
	}, [cart]);

	return (
		// tg.initData === '' && (
		tg.initData !== '' && (
			<div className={`wrapper ${tg.platform === 'ios' ? 'ios' : ''} app`} data-theme={`${theme}`}>
				<Header />
				{/* <div style={{ height: 80 }}></div> */}
				{/* <div>platform {JSON.stringify(tg.platform)}</div> */}
				{/* <div style={{ overflow: 'scroll' }}>themeParams {JSON.stringify(tg.themeParams)}</div> */}

				<div className='content'>
					<Routes>
						<Route path='/' element={<Store />} />
						<Route path='cart' element={<Cart />} />
						<Route path='product/:id' element={<Product />} />
						<Route path='order' element={<Order />} />
						<Route path='payment' element={<Payment />} />
						<Route path='order' element={<Welcome />} />
					</Routes>
				</div>
			</div>
		)
	);
}

export default App;
