import { useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setCartBackPath, setCategory, setScrollReturnPoint } from '../../pages/Store/storeManager';
import { setTheme, Theme } from '../../redux/appManager';
import { useAppDispatch, useAppSelector } from '../../reduxHook';
import CartIcon from '../CartIcon';
import DarkModeIcon from '../DarkModeIcon';
import LightModeIcon from '../LightModeIcon';
import MainLogo from '../MainLogo';
import './header.scss';

export default function Header() {
	const { theme } = useAppSelector(state => state.appManager);
	const { isCartVisible } = useAppSelector(state => state.storeManager);
	const { cart, isCartEnabled } = useAppSelector(state => state.appManager);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		isCartVisible
			? ref?.current?.classList.remove('invisible')
			: ref?.current?.classList.add('invisible');
	}, [isCartVisible]);

	const amountInCart: number = cart.reduce((acc, item) => acc + item.quantity, 0);

	function onCartClick() {
		if (isCartEnabled) {
			if (location.pathname === '/') {
				dispatch(setScrollReturnPoint(window.scrollY || document.documentElement.scrollTop));
			}
			dispatch(setCartBackPath(location.pathname));
			navigate('/cart', { replace: true });
		}
	}
	function onThemeClick() {
		const nextTheme = theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT;
		dispatch(setTheme(nextTheme));
	}

	return (
		<div className='header'>
			{theme === Theme.LIGHT ? (
				<DarkModeIcon className='icon__theme' onClick={onThemeClick} />
			) : (
				<LightModeIcon className='icon__theme' onClick={onThemeClick} />
			)}
			<Link
				to='/'
				replace={true}
				onClick={() => {
					if (location.pathname === '/') {
						window.scrollTo({ top: 0, behavior: 'smooth' });
					} else {
						dispatch(setCategory(0));
						dispatch(setScrollReturnPoint(0));
					}
				}}>
				<div className='header__logo'>
					<MainLogo className='icon__logo' />
					<div>
						<h1>Pro Cocktail</h1>
						<p>здоровье красота настроение</p>
					</div>
				</div>
			</Link>
			<div className='header__cart invisible' ref={ref}>
				<CartIcon
					className={isCartEnabled ? 'icon__cart' : 'icon__cart disabled'}
					onClick={onCartClick}
				/>
				{amountInCart > 0 ? <i onClick={onCartClick}>{amountInCart}</i> : <></>}
			</div>
		</div>
	);
}
