import { useNavigate } from 'react-router-dom';
import { IProduct } from '../../pages/Store/storeManager';
import { setCart } from '../../redux/appManager';
import { useAppDispatch, useAppSelector } from '../../reduxHook';
import addProductToCart from '../../utils/AddProductToCart';
import removeProductFromCart from '../../utils/RemoveProductFromCart';
import AddRemoveBlock from '../AddRemoveBlock/AddRemoveBlock';
import '../Button/button.scss';
import './productItem.scss';

type ProductItemProps = {
	onNavigateToProduct: () => void;
	product: IProduct;
};

const ProductItem: React.FC<ProductItemProps> = props => {
	const { onNavigateToProduct, product } = props;
	const { cart } = useAppSelector(state => state.appManager);
	const dispatch = useAppDispatch();
	const productAmountInCart = cart.find(item => item.product.id === product.id)?.quantity || 0;

	const navigate = useNavigate();

	function onProductItemClick() {
		onNavigateToProduct();
		navigate(`/product/${product.id}`, { replace: true });
	}

	const handleAddProductToCart = () => {
		dispatch(setCart(addProductToCart(false, product, cart)));
	};

	const handleRemoveProductFromCart = () => {
		dispatch(setCart(removeProductFromCart(false, product, cart)));
	};

	return (
		<div className='product-item-wrapper'>
			<div className='product-item'>
				<div className='product-item__top' onClick={onProductItemClick}>
					<img
						className={product.imageUrl !== '' ? 'product-item__image' : ''}
						src={product.imageUrl}
						alt=''
					/>
					{/* <img className='product-item__image' src={product.imageUrl} alt='' /> */}
					{productAmountInCart > 0 ? <i>{productAmountInCart}</i> : <></>}
					{product.promo && <p className='promo'>Акция</p>}
					{product.isNew && <p className='new'>Новинка</p>}
					{product.quantityInStock <= 0 && <p className='ended'>Закончился</p>}
					<h4 className='product-item__title'>{product.title}</h4>
				</div>

				<div className='product-item__bottom'>
					<div className='product-item__price'>{product.promo || product.price} &#8381;</div>
					<AddRemoveBlock
						className='product-item__actions'
						productAmountInCart={productAmountInCart}
						isEnded={product.quantityInStock <= 0}
						handleRemoveProductFromCart={handleRemoveProductFromCart}
						handleAddProductToCart={handleAddProductToCart}
					/>
				</div>
			</div>
		</div>
	);
};

export default ProductItem;
