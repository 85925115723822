import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IProduct {
	id: number;
	imageUrl: string;
	price: number;
	cost: number;
	vp: number;
	title: string;
	short: string;
	description: string;
	quantityInStock: number;
	quantityInTransit: number;
	category: ICategory;
	promo: number;
	isNew: boolean;
	isHidden: boolean;
}

export interface ICategory {
	id: number;
	title: string;
}

export interface ICartItem {
	product: IProduct;
	quantity: number;
}

export interface IClientData {
	deliveryMethod: string;
	name: string;
	lastName: string;
	phone: string;
	index: string;
	city: string;
	street: string;
	home: string;
	litera: string;
	flat: string;
	paymentMethod: string;
}

interface IStoreManagerState {
	categoryId: number;
	scrollReturnPoint: number;
	isCartVisible: boolean;
	cartBackPath: string;
	clientData: IClientData | null;
}

const initialState: IStoreManagerState = {
	categoryId: 0,
	scrollReturnPoint: 0,
	isCartVisible: false,
	cartBackPath: '/',
	clientData: null,
};

export const storeManager = createSlice({
	name: 'storeManager',
	initialState,
	reducers: {
		setCategory(state, action: PayloadAction<number>) {
			state.categoryId = action.payload;
		},
		setScrollReturnPoint(state, action: PayloadAction<number>) {
			state.scrollReturnPoint = action.payload;
		},
		setIsCartVisible(state, action: PayloadAction<boolean>) {
			state.isCartVisible = action.payload;
		},
		setCartBackPath(state, action: PayloadAction<string>) {
			state.cartBackPath = action.payload;
		},
		setClientData(state, action: PayloadAction<IClientData | null>) {
			state.clientData = action.payload;
		},
	},
});

export const {
	setCategory,
	setScrollReturnPoint,
	setIsCartVisible,
	setCartBackPath,
	setClientData,
} = storeManager.actions;
export default storeManager.reducer;
