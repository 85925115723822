import range from 'lodash.range';
import { useEffect, useRef, useState } from 'react';
import Carousel from '../../components/Carousel/Carousel';
import Categories from '../../components/Categories/Categories';
import Loader from '../../components/Loader';
import ProductItem from '../../components/ProductItem/ProductItem';
import { useAppDispatch, useAppSelector } from '../../reduxHook';
import './store.scss';
import {
	ICategory,
	IProduct,
	setCategory,
	setIsCartVisible,
	setScrollReturnPoint,
} from './storeManager';

export default function Store() {
	const { data, dataStatus } = useAppSelector(state => state.appManager);
	const { categoryId, scrollReturnPoint } = useAppSelector(state => state.storeManager);
	const dispatch = useAppDispatch();

	const [currentBannerId, setCurrentBannerId] = useState<number>(0);

	const categoryTabsRef = useRef<HTMLUListElement>(null);
	const ref = useRef<HTMLDivElement>(null);
	const topDivOffset: number = 120;
	const blockScrollOffset: number = 100;
	const titleWidth: number = 30;
	const categoryChangeDelay: number = 200;

	useEffect(() => {
		categoryTabsRef.current?.scrollIntoView({
			behavior: 'instant',
			block: 'start',
			inline: 'start',
		});
	}, [dataStatus]);

	useEffect(() => {
		window.scrollTo(0, scrollReturnPoint);
	}, [scrollReturnPoint]);

	useEffect(() => {
		setTimeout(() => {
			const currentElement = categoryTabsRef?.current?.childNodes[categoryId] as HTMLDivElement;
			currentElement?.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
				inline: 'center',
			});
		}, categoryChangeDelay);
	}, [categoryId]);

	useEffect(() => {
		const docElement = document.documentElement;
		let timerId: undefined | ReturnType<typeof setTimeout> = undefined;
		const handleScroll = () => {
			clearTimeout(timerId);
			timerId = setTimeout(() => {
				const innerHeight = window.innerHeight;
				const scrollTop = window.scrollY || docElement.scrollTop;

				const childNodes = Array.from(ref?.current?.childNodes!) as Array<HTMLDivElement>;
				const categoryNodes = childNodes.filter(child => child.id !== 'banner');

				for (let index = 0; index < categoryNodes.length; index++) {
					const previousNode = categoryNodes[index - 1];
					const node = categoryNodes[index];
					if (
						node.offsetTop <= scrollTop - titleWidth + innerHeight &&
						(previousNode ? previousNode?.offsetTop - blockScrollOffset <= scrollTop : true)
					) {
						node.classList.add('active');
						previousNode?.classList.remove('active');
					} else {
						node.classList.remove('active');
					}

					if (
						node.offsetTop + node.offsetHeight - blockScrollOffset > scrollTop &&
						(previousNode ? previousNode?.offsetTop - blockScrollOffset <= scrollTop : true)
					) {
						if (node.offsetTop <= scrollTop - titleWidth + innerHeight) {
							dispatch(setCategory(Number(node.id) - 1));
						}
					}
				}
			}, categoryChangeDelay);
		};

		window.addEventListener('scroll', handleScroll, false);
		return () => {
			window.removeEventListener('scroll', handleScroll, false);
		};
	}, [categoryId, dispatch]);

	useEffect(() => {
		dispatch(setIsCartVisible(true));
		return () => {
			dispatch(setIsCartVisible(false));
		};
	}, [dispatch]);

	function scrollToCategory(category: ICategory) {
		const childNodes = Array.from(ref?.current?.childNodes!) as Array<HTMLDivElement>;
		const block = childNodes
			.filter(node => node.id !== '')
			.find(node => node.id === category.id.toString());

		block && window.scrollTo({ top: block.offsetTop - topDivOffset, behavior: 'smooth' });
	}

	function onNavigateToProduct() {
		dispatch(setScrollReturnPoint(window.scrollY || document.documentElement.scrollTop));
	}

	if (dataStatus === 'loading') return <Loader className='content__loading' />;
	if (dataStatus === 'error') return <div className='content__disconnect'>Нет соединения!</div>;

	return (
		<div className='container'>
			<div className='content__top'>
				<Categories
					onCategoryClick={(index: number) => scrollToCategory(data.categories[index])}
					ref={categoryTabsRef}
				/>
			</div>
			{data.bannerIds.length > 0 && (
				<div className='content__banner'>
					<Dots amount={data.bannerIds.length} index={currentBannerId} />
					<Carousel onImageChange={index => setCurrentBannerId(index)} />
				</div>
			)}
			<div className='content__items' ref={ref}>
				{data.products?.length > 0 ? (
					data.categories.map((category: ICategory) => (
						<CategoryBlock
							key={category.id}
							category={category}
							products={data.products}
							onNavigateToProduct={onNavigateToProduct}
						/>
					))
				) : (
					<></>
				)}
			</div>
		</div>
	);
}

interface CategoryBlockProps {
	category: ICategory;
	products: IProduct[];
	onNavigateToProduct: () => void;
}

const CategoryBlock: React.FC<CategoryBlockProps> = ({
	category,
	products,
	onNavigateToProduct,
}) => {
	return (
		<div id={category.id.toString()} className='content__block'>
			<h3 className='content__title'>{category.title}</h3>
			{products
				.filter(product => product.category.id === category.id)
				.map(product => (
					<ProductItem
						key={product.id}
						onNavigateToProduct={onNavigateToProduct}
						product={product}
					/>
				))}
		</div>
	);
};

interface IDots {
	amount: number;
	index: number;
}

const Dots: React.FC<IDots> = ({ amount, index }) => {
	return (
		<div className='dots'>
			{range(amount).map((i: number) => (
				<div key={i} className={`dot ${i === index ? 'active' : ''}`}></div>
			))}
		</div>
	);
};
