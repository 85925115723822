// interface ButtonProps {
// 	children: React.ReactNode;
// 	attributes: React.ButtonHTMLAttributes<HTMLButtonElement>;
// 	onClick: React.MouseEventHandler<HTMLButtonElement>;
// }
interface ButtonProps
	extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
		React.AriaAttributes {}
export const Button: React.FC<ButtonProps> = ({ children, ...attributes }) => {
	return <button {...attributes}>{children}</button>;
};

export default Button;

// interface ButtonProps {
// 	children: React.ReactNode;
// 	attributes: React.ButtonHTMLAttributes<HTMLButtonElement>;
// }

// export const Button: React.FC<ButtonProps> = ({ children, attributes }) => {
// 	return (
// 		<button className={attributes.className} {...attributes}>
// 			{children}
// 		</button>
// 	);
// };

// export default Button;

// export default function Button(props, { children  }) {
// 	return (
// 		<div>
// 			<button {...props} className={props.className}>
// 				{children}
// 			</button>
// 			{/* <button {...props} className={'button' + props.className} /> */}
// 		</div>
// 	);
// }
