import { ICartItem, IProduct } from '../pages/Store/storeManager';

export default function removeProductFromCart(
	isForCart: boolean,
	product: IProduct,
	cart: Array<ICartItem>,
): Array<ICartItem> {
	if (isForCart) {
		if (cart.length > 0) {
			return cart
				.map(item =>
					item.product.id === product.id && item.quantity > 1
						? { ...item, quantity: item.quantity - 1 }
						: item,
				)
				.filter(item => item.quantity > 0);
		}
		return [];
	} else {
		if (cart.length > 0) {
			if (cart.some(item => item.product.id === product.id)) {
				return cart
					.map(item =>
						item.product.id === product.id ? { ...item, quantity: item.quantity - 1 } : item,
					)
					.filter(item => item.quantity > 0);
			} else {
				return cart;
			}
		}
		return [];
	}
}
