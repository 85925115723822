import { ICartItem, IProduct } from '../pages/Store/storeManager';

export default function addProductToCart(
	isForCart: boolean,
	product: IProduct,
	cart: Array<ICartItem>,
): Array<ICartItem> {
	if (isForCart) {
		if (cart.length === 0) {
			return [{ product: product, quantity: 1 }];
		} else {
			return cart.map(item =>
				item.product.id === product.id ? { ...item, quantity: item.quantity + 1 } : item,
			);
		}
	} else {
		if (cart.length === 0) {
			return [{ product: product, quantity: 1 }];
		} else {
			if (cart.some(item => item.product.id === product.id)) {
				return cart.map(item =>
					item.product.id === product.id ? { ...item, quantity: item.quantity + 1 } : item,
				);
			} else {
				return [...cart, { product: product, quantity: 1 }];
			}
		}
	}
}
