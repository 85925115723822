import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { PaymentData } from '../@types/yookassa';
import { ICartItem, ICategory, IClientData, IProduct } from '../pages/Store/storeManager';
import { browserInfo } from '../utils/browserInfo';
import useTelegram from '../utils/telegram';
const uuid = require('uuid');

interface IData {
	categories: Array<ICategory>;
	products: Array<IProduct>;
	bannerIds: Array<string>;
	visitorStatus: VisitorStatus;
	visitorIp: string;
}

export enum Theme {
	LIGHT = 'light',
	DARK = 'dark',
}

enum DataStatus {
	LOADING = 'loading',
	SUCCESS = 'success',
	ERROR = 'error',
}

enum OrderStatus {
	ORDERING = 'ordering',
	LOADING = 'loading',
	SUCCESS = 'success',
	ERROR = 'error',
}

export enum VisitorStatus {
	USER = 'user',
	GUEST = 'guest',
}

interface IAppManagerState {
	data: IData;
	dataStatus: DataStatus;
	cart: Array<ICartItem>;
	isCartEnabled: boolean;
	theme: Theme;
	orderStatus: OrderStatus;
}

interface ITelegramData {
	id: string;
	username: string;
}

export interface IOrder {
	clientData: IClientData;
	cart: Array<ICartItem>;
	userData: ITelegramData;
}

export interface ILocalStorageCartItem {
	productId: number;
	quantity: number;
}

const localStorageCart: Array<ILocalStorageCartItem> =
	JSON.parse(localStorage.getItem('cart')!) || [];
const productIdsInCart = localStorageCart.map(cartItem => cartItem.productId);
const sortedLocalStorageCart = localStorageCart.sort((a, b) => a.productId - b.productId);

const initialState: IAppManagerState = {
	data: {
		categories: [],
		products: [],
		bannerIds: [],
		visitorStatus: VisitorStatus.GUEST,
		visitorIp: '',
	},
	dataStatus: DataStatus.LOADING,
	cart: [],
	isCartEnabled: false,
	theme: Theme.LIGHT,
	orderStatus: OrderStatus.ORDERING,
};

const googleSheetsURL =
	'https://script.google.com/macros/s/AKfycbzl6GOBi5N1G24L9UxTOBuP4KrwSqT2Pw5WJqghhQgGdiIRiVcCIBrFDCrYnU-JCL0NPQ/exec';

export const fetchAllData = createAsyncThunk<IData>('data/fetchAllData', async () => {
	const { user } = useTelegram();
	let response = undefined;

	if (user) {
		const dataFromGoogleSheets = await axios.get<IData>(
			googleSheetsURL +
				`?id=${user.id}
			&username=${user.username}
			&firstName=${user.first_name}
			&lastName=${user.last_name}`,
		);
		return {
			categories: dataFromGoogleSheets.data.categories,
			products: dataFromGoogleSheets.data.products.filter(p => !p.isHidden),
			bannerIds: dataFromGoogleSheets.data.bannerIds,
			visitorStatus: user ? VisitorStatus.USER : VisitorStatus.GUEST,
			visitorIp: '',
		};
	} else {
		try {
			response = await axios.get('https://api.ipify.org?format=json');
		} catch (err) {
			// const error = err as AxiosError;
		}
		const dataFromGoogleSheets = await axios.get<IData>(
			googleSheetsURL +
				`?id=${'0'}
				&username=${response ? response.data.ip : ''}
				&firstName=${browserInfo}
				&lastName=${''}`,
		);
		return {
			categories: dataFromGoogleSheets.data.categories,
			products: dataFromGoogleSheets.data.products.filter(p => !p.isHidden),
			bannerIds: dataFromGoogleSheets.data.bannerIds,
			visitorStatus:
				dataFromGoogleSheets.data.visitorStatus === 'user'
					? VisitorStatus.USER
					: VisitorStatus.GUEST,
			visitorIp: response ? response.data.ip : '',
		};
	}
});

/*
export const fetchAllData = createAsyncThunk<IData>('data/fetchAllData', async () => {
	const { user } = useTelegram();
	let response = undefined;

	try {
		response = await axios.get('https://api.ipify.org?format=json');
	} catch (err) {
		// const error = err as AxiosError;
	}
	const dataFromGoogleSheets = await axios.get<IData>(
		googleSheetsURL +
			`?id=${user ? user.id : '0'}
			&username=${user ? user.username : response ? response.data.ip : ''}
			&firstName=${user ? user.first_name : browserInfo}
			&lastName=${user ? user.last_name : ''}`,
	);
	return {
		categories: dataFromGoogleSheets.data.categories,
		products: dataFromGoogleSheets.data.products.filter(p => !p.isHidden),
		bannerIds: dataFromGoogleSheets.data.bannerIds,
		visitorStatus:
			dataFromGoogleSheets.data.visitorStatus === 'user' ? VisitorStatus.USER : VisitorStatus.GUEST,
		visitorIp: response ? response.data.ip : '',
	};
});
*/

export const sendOrder = createAsyncThunk<void, IOrder, { rejectValue: string }>(
	'data/sendOrder',
	async (order, { rejectWithValue }) => {
		try {
			const response = await axios.post<IOrder>(
				googleSheetsURL + '?param=order',
				JSON.stringify(order),
			);
			if (response.status !== 200) {
				throw new Error(response.statusText);
			}
		} catch (error) {
			return rejectWithValue('fail');
		}
	},
);

// export const sendOrder = createAsyncThunk<void, IOrder, { rejectValue: string }>(
// 	'data/sendOrder',
// 	async (order, { rejectWithValue }) => {
// 		try {
// 			const response = await fetch(googleSheetsURL + '?param=order', {
// 				method: 'POST',
// 				body: JSON.stringify(order),
// 			});
// 			if (!response.ok) {
// 				throw new Error(response.statusText);
// 			}
// 		} catch (error) {
// 			return rejectWithValue('fail');
// 		}
// 	},
// );

interface IPayment {
	amount: number;
	orderId: string;
	description: string;
}

export async function createPayment(payment: IPayment) {
	return await axios.post<PaymentData>(
		'https://api.yookassa.ru/v3/payments',
		{
			amount: {
				value: payment.amount.toString(),
				currency: 'RUB',
			},
			capture: true,
			description: payment.description,
			metadata: {
				order_id: payment.orderId,
			},
			confirmation: {
				type: 'redirect',
				return_url: process.env.YOOKASSA_CALLBACK_URL,
			},
		},
		{
			auth: {
				username: process.env.YOOKASSA_STORE_ID as string,
				password: process.env.YOOKASSA_API_KEY as string,
			},
			headers: {
				'Content-Type': 'application/json',
				'Idempotence-Key': uuid.v4().toString(),
			},
		},
	);
}

/*
export const createPayment = createAsyncThunk<void, IPayment, { rejectValue: string }>(
	'data/createPayment',
	async (payment, { rejectWithValue }) => {
		try {
			const response = await axios.post<PaymentData>(
				'https://api.yookassa.ru/v3/payments',
				{
					amount: {
						value: payment.amount.toString(),
						currency: 'RUB',
					},
					capture: true,
					description: payment.description,
					metadata: {
						order_id: payment.orderId,
					},
					confirmation: {
						type: 'redirect',
						return_url: process.env.YOOKASSA_CALLBACK_URL,
					},
				},
				{
					auth: {
						username: process.env.YOOKASSA_STORE_ID as string,
						password: process.env.YOOKASSA_API_KEY as string,
					},
					headers: {
						'Content-Type': 'application/json',
						'Idempotence-Key': randomUUID().toString(),
						// 'Idempotence-Key': Math.random().toString(36).substring(7),
					},
				},
			);
			if (response.status !== 200) {
				throw new Error(response.statusText);
			}
		} catch (error) {
			return rejectWithValue('fail');
		}
	},
);
*/

export const appManager = createSlice({
	name: 'appManager',
	initialState,
	reducers: {
		setTheme(state, action: PayloadAction<Theme>) {
			state.theme = action.payload;
		},
		setCart(state, action: PayloadAction<Array<ICartItem>>) {
			state.cart = action.payload;
			if (action.payload.length > 0) {
				state.isCartEnabled = true;
			} else {
				state.isCartEnabled = false;
			}
		},
		resetOrderStatus(state) {
			state.orderStatus = OrderStatus.ORDERING;
		},
		resetCart(state) {
			state.cart = [];
			localStorage.setItem('cart', JSON.stringify([]));
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchAllData.pending, state => {
				state.dataStatus = DataStatus.LOADING;
				state.data = {
					categories: [],
					products: [],
					bannerIds: [],
					visitorStatus: VisitorStatus.GUEST,
					visitorIp: '',
				};
			})
			.addCase(fetchAllData.fulfilled, (state, action) => {
				const filteredNSortedProducts = action.payload.products
					.filter(product => productIdsInCart.includes(product.id))
					.sort((a, b) => a.id - b.id);
				const cart: Array<ICartItem> = [];
				for (let i = 0; i < sortedLocalStorageCart.length; i++) {
					if (
						filteredNSortedProducts[i]?.quantityInStock > 0 &&
						!filteredNSortedProducts[i]?.isHidden
					) {
						cart.push({
							product: filteredNSortedProducts[i],
							quantity: sortedLocalStorageCart[i].quantity,
						});
					}
				}
				state.cart = cart;
				if (cart.length > 0) {
					state.isCartEnabled = true;
				}
				state.data = action.payload;
				state.dataStatus = DataStatus.SUCCESS;
			})
			.addCase(fetchAllData.rejected, state => {
				state.dataStatus = DataStatus.ERROR;
				state.data = {
					categories: [],
					products: [],
					bannerIds: [],
					visitorStatus: VisitorStatus.GUEST,
					visitorIp: '',
				};
			})
			.addCase(sendOrder.pending, state => {
				state.orderStatus = OrderStatus.LOADING;
			})
			.addCase(sendOrder.fulfilled, state => {
				state.orderStatus = OrderStatus.SUCCESS;
			})
			.addCase(sendOrder.rejected, state => {
				state.orderStatus = OrderStatus.ERROR;
			});
	},
});

export const { setTheme, setCart, resetOrderStatus, resetCart } = appManager.actions;
export default appManager.reducer;
