import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddRemoveBlock from '../../components/AddRemoveBlock/AddRemoveBlock';
import BackIcon from '../../components/BackIcon';
import '../../components/Button/button.scss';
import Loader from '../../components/Loader';
import { setCart } from '../../redux/appManager';
import { useAppDispatch, useAppSelector } from '../../reduxHook';
import addProductToCart from '../../utils/AddProductToCart';
import removeProductFromCart from '../../utils/RemoveProductFromCart';
import { setIsCartVisible } from '../Store/storeManager';
import './product.scss';

export default function Product() {
	const { id } = useParams();
	const { data, dataStatus, cart } = useAppSelector(state => state.appManager);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	var product = data.products.find(item => item.id.toString() === id);
	const productAmountInCart: number =
		cart.find(item => item.product.id.toString() === id)?.quantity || 0;

	useEffect(() => {
		dataStatus === 'success' && !product && navigate('/', { replace: true });
	}, [dataStatus, navigate, product]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		dispatch(setIsCartVisible(true));
		return () => {
			dispatch(setIsCartVisible(false));
		};
	}, [dispatch]);

	const handleAddProductToCart: () => void = () => {
		product && dispatch(setCart(addProductToCart(false, product, cart)));
	};

	const handleRemoveProductFromCart = () => {
		product && dispatch(setCart(removeProductFromCart(false, product, cart)));
	};

	if (dataStatus === 'loading') return <Loader className='content__loading' />;
	if (dataStatus === 'error') return <div className='content__disconnect'>Нет соединения!</div>;

	if (product) {
		return (
			<div className='product'>
				<div className='product__container'>
					{product && (
						<div className='product__container__content'>
							<BackIcon onIconClick={() => navigate('/', { replace: true })} />
							<img className='product__image' src={product.imageUrl} alt='' />
							{productAmountInCart > 0 ? (
								<i className='product__image__amount'>{productAmountInCart}</i>
							) : (
								<></>
							)}
							{product.promo && <p className='promo'>Акция</p>}
							{product.isNew && <p className='new'>Новинка</p>}
							<h4 className='product__title'>{product.title}</h4>
							<div className='product__price'>{product.promo || product.price} &#8381;</div>
							<AddRemoveBlock
								className='product__actions'
								productAmountInCart={productAmountInCart}
								isEnded={product.quantityInStock <= 0}
								handleRemoveProductFromCart={handleRemoveProductFromCart}
								handleAddProductToCart={handleAddProductToCart}
							/>
							<p className='product__text'>{product.short}</p>
							<p className='product__text'>{product.description}</p>
						</div>
					)}
				</div>
			</div>
		);
	} else {
		return <></>;
	}
}
