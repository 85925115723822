export default function Loader({ ...attributes }) {
	return (
		<div className={attributes.className} {...attributes}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='40'
				height='40'
				viewBox='0 0 224 224'
				fill='none'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M93.3797 1.51256C52.3407 8.77656 18.7047 37.8056 5.47671 77.3766C2.46771 86.3766 -0.506291 103.126 0.398709 105.976C1.25071 108.664 5.72171 111.918 8.55971 111.918C13.0417 111.918 15.4777 108.096 16.8117 98.9696C23.0637 56.2136 56.1757 23.1016 98.9317 16.8496C110.423 15.1696 113.937 11.5256 110.77 4.57456C109.604 2.01656 105.967 -0.144439 103.083 0.00756097C102.146 0.056561 97.7797 0.73456 93.3797 1.51256ZM179.88 1.88956C176.359 3.78456 158.096 21.5236 158.932 22.2366C159.178 22.4466 162.16 24.0816 165.557 25.8686C178.227 32.5346 190.273 44.5436 197.557 57.7686C199.571 61.4256 201.382 64.6196 201.581 64.8656C202.309 65.7676 220.086 47.3056 221.954 43.7076C224.107 39.5596 224.397 35.4346 222.83 31.2326C221.429 27.4766 197.243 3.08556 193.162 1.31456C188.802 -0.57844 184.086 -0.374439 179.88 1.88956ZM112.88 33.4726C102.751 35.6486 90.8207 42.0656 82.7467 49.6816L75.7517 56.2806L121.566 102.143L167.38 148.005L174.023 141.08C185.2 129.429 191.845 112.962 191.845 96.9176C191.845 73.0096 177.26 49.5436 155.88 39.0526C141.972 32.2276 127.45 30.3436 112.88 33.4726ZM33.4307 117.179C9.85371 150.982 0.361711 165.373 0.0587107 167.775C-0.347289 171.001 0.664707 172.161 26.0287 197.525C48.7477 220.243 52.8367 223.918 55.4027 223.918C57.6937 223.918 69.3817 216.257 105.882 190.833C132.006 172.637 153.475 157.655 153.59 157.54C153.706 157.425 134.131 137.668 110.09 113.635L66.3797 69.9396L33.4307 117.179ZM210.477 114.23C208.723 115.984 207.871 118.551 206.948 124.866C200.696 167.622 167.584 200.734 124.828 206.986C115.58 208.338 111.88 210.757 111.88 215.449C111.88 218.288 116.727 223.918 119.172 223.918C119.967 223.918 124.614 223.237 129.499 222.404C177.508 214.222 214.184 177.546 222.366 129.537C224.335 117.987 224.36 118.244 220.957 114.841C217.4 111.284 213.642 111.065 210.477 114.23Z'
					fill='#8bcff7'
				/>
			</svg>
		</div>
	);
}
