import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import BackIcon from '../../components/BackIcon';
import Loader from '../../components/Loader';
import { sendOrder } from '../../redux/appManager';
import { useAppDispatch, useAppSelector } from '../../reduxHook';
import useTelegram from '../../utils/telegram';
import { IClientData, setClientData } from '../Store/storeManager';
import './order.scss';

export default function Order() {
	const deliveryRef = useRef<HTMLDivElement>(null);
	const paymentRef = useRef<HTMLDivElement>(null);
	const addressRef = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { cart, orderStatus, dataStatus, data } = useAppSelector(state => state.appManager);
	const { user } = useTelegram();

	const deliveryData: string | null = useMemo(() => localStorage.getItem('deliveryData'), []);
	const clientDataForPost = useMemo(
		() => JSON.parse(localStorage.getItem('clientDataForPost')!) || null,
		[],
	);
	const clientDataForSDEK = useMemo(
		() => JSON.parse(localStorage.getItem('clientDataForSDEK')!) || null,
		[],
	);

	interface IForm {
		name: string;
		lastName: string;
		phone: string;
		index: string;
		city: string;
		street: string;
		home: string;
		litera: string;
		flat: string;
		paymentMethod: string;
	}

	enum DeliveryMethod {
		POST = 'byPost',
		SDEK = 'bySdek',
	}
	console.log('DeliveryMethod', DeliveryMethod.SDEK);

	const submit = async () => {
		const clearPostForm: IForm = {
			name: postForm.name.trim(),
			lastName: postForm.lastName.trim(),
			phone: postForm.phone,
			index: postForm.index,
			city: postForm.city.trim(),
			street: postForm.street.trim(),
			home: postForm.home,
			litera: postForm.litera,
			flat: postForm.flat,
			paymentMethod: postForm.paymentMethod,
		};

		const clearSdekForm: IForm = {
			name: sdekForm.name.trim(),
			lastName: sdekForm.lastName.trim(),
			phone: sdekForm.phone,
			index: sdekForm.index,
			city: sdekForm.city.trim(),
			street: sdekForm.street.trim(),
			home: sdekForm.home,
			litera: sdekForm.litera,
			flat: sdekForm.flat,
			paymentMethod: sdekForm.paymentMethod,
		};

		const clientData: () => IClientData = () => {
			if (deliveryMethod === 'byPost') {
				return { ...clearPostForm, deliveryMethod: 'byPost' };
			} else {
				return { ...clearSdekForm, deliveryMethod: 'bySdek' };
			}
		};

		dispatch(setClientData(clientData()));
		localStorage.setItem('deliveryData', deliveryMethod!);
		localStorage.setItem('clientDataForPost', JSON.stringify(clearPostForm));
		localStorage.setItem('clientDataForSDEK', JSON.stringify(clearSdekForm));

		dispatch(
			sendOrder({
				clientData: clientData(),
				cart: cart,
				userData: {
					id: user ? user.id : '0',
					username: user ? user.username : data.visitorIp,
				},
			}),
		);
	};

	useEffect(() => {
		if (orderStatus === 'success') {
			navigate('/payment', { replace: true });
		}
	}, [navigate, orderStatus]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (deliveryData) {
			addressRef?.current?.classList.add('shown');
			setPostForm(clientDataForPost);
			setSdekForm(clientDataForSDEK);
		}
	}, [deliveryData, clientDataForPost, clientDataForSDEK, dataStatus]);

	const emptyForm: IForm = {
		name: '',
		lastName: '',
		phone: '',
		index: '',
		city: '',
		street: '',
		home: '',
		litera: '',
		flat: '',
		paymentMethod: '',
	};

	const [postForm, setPostForm] = useState<IForm>(emptyForm);
	const [sdekForm, setSdekForm] = useState<IForm>(emptyForm);
	const [deliveryMethod, setDeliveryMethod] = useState<string | null>(deliveryData);
	const [isIndexDisabled, setIsIndexDisabled] = useState(
		!deliveryData ? false : deliveryData === 'byPost' ? false : true,
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
	} = useForm({
		defaultValues: deliveryMethod
			? {
					name: '-',
					lastName: '-',
					phone: '-',
					index: '      ',
					city: '-',
					street: '-',
					home: '-',
					litera: '-',
					flat: '-',
			  }
			: {
					name: '',
					lastName: '',
					phone: '',
					deliveryMethod: '',
					index: '      ',
					city: '',
					street: '',
					home: '',
					litera: '',
					flat: '',
					paymentMethod: '',
			  },
	});

	if (dataStatus === 'loading' || orderStatus === 'loading') {
		return <Loader className='content__loading' />;
	}
	if (dataStatus === 'error' || orderStatus === 'error') {
		return <div className='content__disconnect'>Нет соединения!</div>;
	}

	return (
		<div className='order'>
			<div className='order__top'>
				<BackIcon onIconClick={() => navigate('/cart', { replace: true })} />
				{localStorage.length > 1 && (
					<button
						className='button-outline'
						type='button'
						onClick={() => {
							// localStorage.clear();
							clearErrors();
							localStorage.removeItem('deliveryData');
							localStorage.removeItem('clientDataForPost');
							localStorage.removeItem('clientDataForSDEK');
							addressRef?.current?.classList.remove('shown');
							setPostForm(emptyForm);
							setSdekForm(emptyForm);
							setDeliveryMethod(null);
						}}>
						Очистить
					</button>
				)}
			</div>

			<form onSubmit={handleSubmit(submit)}>
				<div className='order__client-data'>
					<TextField
						label='Имя'
						type='text'
						name='name'
						placeholder='Ольга'
						register={{
							...register('name', {
								required: true,
								validate: value => {
									return value.trim().length > 0;
								},
							}),
						}}
						className={errors.name ? 'error' : ''}
						inputValue={
							!deliveryMethod || deliveryMethod === 'byPost' ? postForm.name : sdekForm.name
						}
						onInputChange={value => {
							if (deliveryMethod) {
								deliveryMethod === 'byPost'
									? setPostForm({ ...postForm, name: value })
									: setSdekForm({ ...sdekForm, name: value });
							} else {
								setPostForm({ ...postForm, name: value });
								setSdekForm({ ...sdekForm, name: value });
							}
						}}
						maxLength={25}
					/>
					<TextField
						label='Фамилия'
						type='text'
						name='lastName'
						placeholder='Семёнова'
						register={{
							...register('lastName', {
								required: true,
								validate: value => {
									return value.trim().length > 0;
								},
							}),
						}}
						className={errors.lastName ? 'error' : ''}
						inputValue={
							!deliveryMethod || deliveryMethod === 'byPost' ? postForm.lastName : sdekForm.lastName
						}
						onInputChange={value => {
							if (deliveryMethod) {
								deliveryMethod === 'byPost'
									? setPostForm({ ...postForm, lastName: value })
									: setSdekForm({ ...sdekForm, lastName: value });
							} else {
								setPostForm({ ...postForm, lastName: value });
								setSdekForm({ ...sdekForm, lastName: value });
							}
						}}
						maxLength={25}
					/>
					<TextField
						label='телефон'
						type='tel'
						name='phone'
						placeholder='+79008507520'
						register={{
							...register('phone', {
								required: true,
								validate: value => {
									return value.trim().length > 0;
								},
							}),
						}}
						maxLength={12}
						className={errors.phone ? 'error' : ''}
						inputValue={
							!deliveryMethod || deliveryMethod === 'byPost' ? postForm.phone : sdekForm.phone
						}
						onInputChange={value => {
							if (value.length < 2 && value.slice(-1) === '+') {
								if (deliveryMethod) {
									deliveryMethod === 'byPost'
										? setPostForm({ ...postForm, phone: value })
										: setSdekForm({ ...sdekForm, phone: value });
								} else {
									setPostForm({ ...postForm, phone: value });
									setSdekForm({ ...sdekForm, phone: value });
								}
							} else if (value.length === 0) {
								if (deliveryMethod) {
									deliveryMethod === 'byPost'
										? setPostForm({ ...postForm, phone: '' })
										: setSdekForm({ ...sdekForm, phone: '' });
								} else {
									setPostForm({ ...postForm, phone: '' });
									setSdekForm({ ...sdekForm, phone: '' });
								}
							} else {
								if (Number.isInteger(parseInt(value.slice(-1))) && value.length <= 20)
									if (deliveryMethod) {
										deliveryMethod === 'byPost'
											? setPostForm({ ...postForm, phone: value })
											: setSdekForm({ ...sdekForm, phone: value });
									} else {
										setPostForm({ ...postForm, phone: value });
										setSdekForm({ ...sdekForm, phone: value });
									}
							}
						}}
					/>
				</div>
				<h3>Способ доставки</h3>
				<div className='order__delivery-method' ref={deliveryRef}>
					<RadioButton
						label='Почта России'
						name='deliveryMethod'
						value='byPost'
						register={{ ...register('deliveryMethod', { required: true }) }}
						className={
							errors.deliveryMethod ? 'error' : deliveryMethod === 'byPost' ? 'checked' : ''
						}
						onInputChange={value => {
							addressRef?.current?.classList.add('shown');
							clearErrors();
							setDeliveryMethod(value);
							setIsIndexDisabled(false);
						}}
						checked={deliveryMethod === 'byPost'}
					/>
					<RadioButton
						label='СДЭК'
						name='deliveryMethod'
						value='bySDEK'
						register={{ ...register('deliveryMethod', { required: true }) }}
						className={
							errors.deliveryMethod ? 'error' : deliveryMethod === 'bySDEK' ? 'checked' : ''
						}
						onInputChange={value => {
							addressRef?.current?.classList.add('shown');
							clearErrors();
							clearErrors('index');
							setDeliveryMethod(value);
							setIsIndexDisabled(true);
						}}
						checked={deliveryMethod === 'bySDEK'}
					/>
				</div>
				<div className='order__delivery-address' ref={addressRef}>
					{!deliveryMethod || deliveryMethod === 'byPost' ? (
						<h5>Ваш домашний адрес</h5>
					) : (
						<h5>Адрес удобного вам пункта СДЭК</h5>
					)}

					<TextField
						label='индекс'
						type='number'
						name='index'
						placeholder='192354'
						register={{
							...register('index', {
								required: isIndexDisabled ? false : true,
								minLength: !isIndexDisabled ? 6 : 0,
							}),
						}}
						maxLength={6}
						className={errors.index ? 'error' : ''}
						isDisabled={isIndexDisabled}
						inputValue={deliveryMethod === 'byPost' ? postForm.index : sdekForm.index}
						onInputChange={value => {
							if (Number.isInteger(parseInt(value))) {
								if (value.length <= 6) {
									deliveryMethod === 'byPost'
										? setPostForm({ ...postForm, index: value })
										: setSdekForm({ ...sdekForm, index: '-' });
								}
							} else {
								deliveryMethod === 'byPost'
									? setPostForm({ ...postForm, index: '-' })
									: setSdekForm({ ...sdekForm, index: '-' });
							}
						}}
					/>
					<TextField
						label='город / посёлок / деревня / т.п.'
						type='text'
						name='city'
						placeholder='г. Санкт-Петербург'
						register={{
							...register('city', {
								required: true,
								validate: value => {
									return value.trim().length > 0;
								},
							}),
						}}
						className={errors.city ? 'error' : ''}
						inputValue={deliveryMethod === 'byPost' ? postForm.city : sdekForm.city}
						onInputChange={value => {
							deliveryMethod === 'byPost'
								? setPostForm({ ...postForm, city: value })
								: setSdekForm({ ...sdekForm, city: value });
						}}
						maxLength={30}
					/>
					<TextField
						label='улица / проспект / бульвар / т.п.'
						type='text'
						name='street'
						placeholder='Дворцовая набережная'
						register={{
							...register('street', {
								required: true,
								validate: value => {
									return value.trim().length > 0;
								},
							}),
						}}
						className={errors.street ? 'error' : ''}
						inputValue={deliveryMethod === 'byPost' ? postForm.street : sdekForm.street}
						onInputChange={value => {
							deliveryMethod === 'byPost'
								? setPostForm({ ...postForm, street: value })
								: setSdekForm({ ...sdekForm, street: value });
						}}
						maxLength={30}
					/>
					<TextField
						label='дом / строение / т.п.'
						type='text'
						name='home'
						placeholder='дом 20'
						register={{
							...register('home', {
								required: true,
								validate: value => {
									return value.trim().length > 0;
								},
							}),
						}}
						className={errors.home ? 'error' : ''}
						inputValue={deliveryMethod === 'byPost' ? postForm.home : sdekForm.home}
						onInputChange={value => {
							value = value.trim();
							deliveryMethod === 'byPost'
								? setPostForm({ ...postForm, home: value })
								: setSdekForm({ ...sdekForm, home: value });
						}}
						maxLength={15}
					/>
					<TextField
						label='корпус / литера / т.п.'
						type='text'
						name='litera'
						placeholder='-'
						register={{ ...register('litera', { required: false }) }}
						className={''}
						inputValue={deliveryMethod === 'byPost' ? postForm.litera : sdekForm.litera}
						onInputChange={value => {
							value = value.trim();
							deliveryMethod === 'byPost'
								? setPostForm({ ...postForm, litera: value })
								: setSdekForm({ ...sdekForm, litera: value });
						}}
						maxLength={10}
					/>
					<TextField
						label='квартира / офис / помещение / т.п.'
						type='text'
						name='flat'
						placeholder='комната 307'
						register={{
							...register('flat', {
								required: true,
								validate: value => {
									return value.trim().length > 0;
								},
							}),
						}}
						className={errors.flat ? 'error' : ''}
						inputValue={deliveryMethod === 'byPost' ? postForm.flat : sdekForm.flat}
						onInputChange={value => {
							value = value.trim();
							deliveryMethod === 'byPost'
								? setPostForm({ ...postForm, flat: value })
								: setSdekForm({ ...sdekForm, flat: value });
						}}
						maxLength={15}
					/>
				</div>
				<h3>Способ оплаты</h3>
				<div className='order__payment-method' ref={paymentRef}>
					<RadioButton
						label='Перевод на карту'
						name='paymentMethod'
						value='transfer'
						register={{ ...register('paymentMethod', { required: true }) }}
						className={
							errors.paymentMethod
								? 'error'
								: !deliveryMethod || deliveryMethod === 'byPost'
								? postForm.paymentMethod === 'transfer'
									? 'checked'
									: ''
								: sdekForm.paymentMethod === 'transfer'
								? 'checked'
								: ''
						}
						onInputChange={value => {
							clearErrors('paymentMethod');
							if (deliveryMethod) {
								deliveryMethod === 'byPost'
									? setPostForm({ ...postForm, paymentMethod: value })
									: setSdekForm({ ...sdekForm, paymentMethod: value });
							} else {
								setPostForm({ ...postForm, paymentMethod: value });
								setSdekForm({ ...sdekForm, paymentMethod: value });
							}
						}}
						checked={
							!deliveryMethod || deliveryMethod === 'byPost'
								? postForm.paymentMethod === 'transfer'
								: sdekForm.paymentMethod === 'transfer'
						}
					/>
					<RadioButton
						label='QR-код СБП'
						name='paymentMethod'
						value='online'
						register={{ ...register('paymentMethod', { required: true }) }}
						className={
							errors.paymentMethod
								? 'error'
								: !deliveryMethod || deliveryMethod === 'byPost'
								? postForm.paymentMethod === 'online'
									? 'checked'
									: ''
								: sdekForm.paymentMethod === 'online'
								? 'checked'
								: ''
						}
						onInputChange={value => {
							clearErrors('paymentMethod');
							if (deliveryMethod) {
								deliveryMethod === 'byPost'
									? setPostForm({ ...postForm, paymentMethod: value })
									: setSdekForm({ ...sdekForm, paymentMethod: value });
							} else {
								setPostForm({ ...postForm, paymentMethod: value });
								setSdekForm({ ...sdekForm, paymentMethod: value });
							}
						}}
						checked={
							!deliveryMethod || deliveryMethod === 'byPost'
								? postForm.paymentMethod === 'online'
								: sdekForm.paymentMethod === 'online'
						}
					/>
				</div>
				<div className='order__submit'>
					<button className='button-filled' type='submit'>
						Оплатить
					</button>
				</div>
			</form>
		</div>
	);
}

interface IRadioButton {
	label: string;
	name: string;
	value: string;
	register: any;
	className: string;
	onInputChange: (value: string) => void;
	checked: boolean;
}

const RadioButton: React.FC<IRadioButton> = ({
	label,
	value,
	name,
	className,
	register,
	onInputChange,
	checked,
}) => {
	return (
		<div className='order__radio-button'>
			<label htmlFor={value} className={className}>
				<input
					name={name}
					type='radio'
					id={value}
					value={value}
					{...register}
					onChange={e => onInputChange(e.target.value)}
					checked={checked}
				/>
				{label}
			</label>
		</div>
	);
};

interface ITextField {
	label: string;
	type: string;
	name: string;
	placeholder: string;
	register: any;
	maxLength: number;
	inputValue: string;
	onInputChange: (value: string) => void;
	className: string;
	isDisabled?: boolean;
}

const TextField: React.FC<ITextField> = ({
	label,
	type,
	name,
	placeholder,
	register,
	maxLength,
	inputValue,
	onInputChange,
	className,
	isDisabled,
}) => {
	return (
		<div>
			<label className={isDisabled ? 'disabled' : ''} htmlFor={name}>
				{label}
			</label>
			<input
				disabled={isDisabled}
				name={name}
				type={type}
				onInput={e => {
					const target = e.target as HTMLInputElement;
					onInputChange(target.value);
				}}
				value={inputValue}
				{...register}
				id={name}
				placeholder={placeholder}
				maxLength={maxLength}
				className={className}
			/>
		</div>
	);
};
