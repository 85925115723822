import { forwardRef } from 'react';
import { ICategory, setCategory } from '../../pages/Store/storeManager';
import { useAppDispatch, useAppSelector } from '../../reduxHook';
import './categories.scss';

interface ICategories {
	onCategoryClick: (index: number) => void;
}

const Categories = forwardRef<HTMLUListElement, ICategories>(({ onCategoryClick }, ref) => {
	const { data } = useAppSelector(state => state.appManager);
	const { categoryId } = useAppSelector(state => state.storeManager);
	const dispatch = useAppDispatch();

	return (
		<div className='categories'>
			<ul id='horizontal_scroll' ref={ref}>
				{data.categories.map((category: ICategory, index: number) => (
					<li
						key={category.id}
						onClick={() => {
							dispatch(setCategory(index));
							onCategoryClick(index);
						}}
						className={categoryId === index ? 'active' : ''}>
						{category.title}
					</li>
				))}
			</ul>
		</div>
	);
});
export default Categories;
