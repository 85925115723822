export default function CartIcon({ ...attributes }) {
	return (
		<div className={attributes.className} {...attributes}>
			<svg width='24px' height='24px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 651.53 650'>
				<path
					d='M54.56,473.51c-28.6-114.4-42.9-171.59-12.87-210s89-38.46,206.9-38.46H402.94c117.91,0,176.87,0,206.9,38.46S625.57,359.11,597,473.51c-18.19,72.75-27.29,109.13-54.41,130.31S477.94,625,402.94,625H248.59c-75,0-112.5,0-139.62-21.18S72.75,546.26,54.56,473.51Z'
					fill='none'
					stroke='black'
					strokeMiterlimit='133.33'
					strokeWidth='50'
				/>
				<path
					d='M575.76,241.67l-23.68-86.85C543,121.33,538.39,104.59,529,92a83.28,83.28,0,0,0-36.56-27.93c-14.63-5.72-32-5.72-66.7-5.72m-350,183.34,23.68-86.85c9.14-33.49,13.7-50.23,23.07-62.84a83.22,83.22,0,0,1,36.56-27.93c14.63-5.72,32-5.72,66.69-5.72'
					fill='none'
					stroke='black'
					strokeMiterlimit='133.33'
					strokeWidth='50'
				/>
				<path
					d='M225.76,58.33A33.34,33.34,0,0,1,259.1,25H392.43a33.34,33.34,0,0,1,0,66.67H259.1A33.34,33.34,0,0,1,225.76,58.33Z'
					fill='none'
					stroke='black'
					strokeMiterlimit='133.33'
					strokeWidth='50'
				/>
				<path
					d='M192.43,358.33V491.67'
					fill='none'
					stroke='black'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='50'
				/>
				<path
					d='M459.1,358.33V491.67'
					fill='none'
					stroke='black'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='50'
				/>
				<path
					d='M325.76,358.33V491.67'
					fill='none'
					stroke='black'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='50'
				/>
			</svg>
		</div>
	);
}
