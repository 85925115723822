export default function MainLogo({ ...attributes }) {
	return (
		<div className={attributes.className} {...attributes}>
			<svg
				width='40px'
				height='40px'
				viewBox='0 0 1024 1024'
				version='1.1'
				xmlns='http://www.w3.org/2000/svg'>
				<path
					className='content'
					d='M301.544 878.136c0 31.128 40.928 56.36 184.12 56.36s184.128-25.232 184.128-56.36l22.536-199.16c0 31.136-70.984 56.368-214.184 56.368-143.184 0-199.144-25.232-199.144-56.368l22.544 199.16z'
					fill='#e9e5be'
				/>
				<path
					className='stroke'
					d='M485.664 950.488c-85.384 0-198.72-7.408-200.104-71.176l-59.336-433.888a30.744 30.744 0 0 1-0.8-6.928 16 16 0 0 1 31.848-2.168l0.336 2.424c6.136 13.904 79.672 40.12 228.056 40.12 148.384 0 221.912-26.2 228.056-40.12l0.336-2.416a16 16 0 1 1 31.848 2.16c0 2.32-0.272 4.648-0.8 6.928l-59.328 433.888c-1.384 63.776-114.728 71.176-200.112 71.176zM263.288 480.264l54.112 395.696c0.096 0.72 0.152 1.448 0.152 2.168 0 9.472 12.128 40.36 168.12 40.36 156 0 168.128-30.888 168.128-40.36 0-0.728 0.048-1.456 0.152-2.168l54.104-395.704c-64.4 28.76-191.328 30.592-222.376 30.592-31.064 0.008-157.992-1.824-222.392-30.584z'
					fill='#006600'
				/>
				<path
					className='content'
					d='M275.24 678.976a210.424 56.368 0 1 0 420.848 0 210.424 56.368 0 1 0-420.848 0Z'
					fill='#e9e5be'
				/>
				<path
					className='stroke'
					d='M485.664 751.344c-78.312 0-226.424-15.128-226.424-72.368 0-57.232 148.112-72.36 226.424-72.36 78.312 0 226.424 15.128 226.424 72.36 0 57.24-148.112 72.368-226.424 72.368z m0-112.728c-129.496 0-192.384 29.296-194.424 40.432 2.04 10.992 64.928 40.296 194.424 40.296s192.384-29.304 194.424-40.44c-2.04-10.992-64.928-40.288-194.424-40.288z'
					fill='#006600'
				/>
				<path
					className='stroke'
					d='M418.024 818.008m-26.304 0a26.304 26.304 0 1 0 52.608 0 26.304 26.304 0 1 0-52.608 0Z'
					fill='#006600'
				/>
				<path
					className='stroke'
					d='M529.512 874.368m-18.792 0a18.792 18.792 0 1 0 37.584 0 18.792 18.792 0 1 0-37.584 0Z'
					fill='#006600'
				/>
				<path
					className='stroke'
					d='M545.784 795.464m-18.784 0a18.784 18.784 0 1 0 37.568 0 18.784 18.784 0 1 0-37.568 0Z'
					fill='#006600'
				/>
				<path
					className='stroke'
					d='M632.24 939.704a24 24 0 0 1-23.808-27.248l60.12-439.632a24 24 0 1 1 47.552 6.504l-60.12 439.632a24 24 0 0 1-23.744 20.744z'
					fill='#006600'
				/>
				<path
					className='stroke'
					d='M398.672 203.216c-2.568 0-5.144-0.416-7.624-1.24l-141.248-47.288a24 24 0 0 1 15.24-45.52l121.312 40.608c24.952-42.136 101.984-128.256 311.824-121.84a24.016 24.016 0 0 1-1.496 47.984c-235.096-7.336-274.744 109.352-275.12 110.528a24.024 24.024 0 0 1-22.888 16.768z'
					fill='#006600'
				/>
				<path
					className='fill'
					d='M489.424 288.184v-67.632c0-47.736 38.688-86.424 86.424-86.424 47.736 0 86.432 38.688 86.432 86.424v86.424l-172.856-18.792z'
					fill='#00aa00'
				/>
				<path
					className='stroke'
					d='M662.272 322.976c-0.576 0-1.152-0.032-1.728-0.096l-172.848-18.792a16 16 0 0 1-14.272-15.904v-67.632c0-56.472 45.944-102.424 102.424-102.424 56.48 0 102.432 45.944 102.432 102.424v86.424a16 16 0 0 1-16.008 16z m-156.848-49.144l140.848 15.304v-68.584c0-38.832-31.592-70.424-70.432-70.424-38.832 0-70.424 31.592-70.424 70.424v53.28z'
					fill='#006600'
				/>
				<path
					className='fill'
					d='M241.424 331.928c0-31.136 101.056-55.016 244.24-55.016 143.192 0 244.24 25.232 244.24 56.368v105.208c0 31.136-101.048 56.368-244.24 56.368-143.184 0-244.24-27.8-244.24-58.928v-104z'
					fill='#00aa00'
				/>
				<path
					className='stroke'
					d='M485.664 510.856c-96.656 0-260.24-15.784-260.24-74.928v-104c0-67.592 216.784-71.016 260.24-71.016 43.456 0 260.24 3.496 260.24 72.368v105.208c0 68.88-216.784 72.368-260.24 72.368z m0-217.944c-150.336 0-223.928 26.088-228.288 39.392l0.048 103.624c3.56 12.424 74.336 42.928 228.24 42.928 150.504 0 224-26.96 228.28-40.712l-0.04-104.864c-4.24-13.408-77.736-40.368-228.24-40.368z'
					fill='#006600'
				/>
				<path
					className='stroke'
					d='M485.664 405.64c-43.456 0-260.24-3.496-260.24-72.36a16 16 0 0 1 32 0c4.24 13.408 77.744 40.36 228.24 40.36 150.504 0 224-26.952 228.28-40.704 0-8.832 7.144-15.832 15.984-15.832s15.976 7.336 15.976 16.168c0 68.872-216.784 72.368-260.24 72.368z'
					fill='#006600'
				/>
				<path
					className='fill'
					d='M429.296 246.856a45.104 45.104 0 0 1-90.184 0v-75.152a45.088 45.088 0 1 1 90.184 0v75.152z'
					fill='#00aa00'
				/>
				<path
					className='stroke'
					d='M384.208 307.944c-33.688 0-61.096-27.408-61.096-61.088v-75.152c0-33.68 27.408-61.088 61.096-61.088 33.68 0 61.088 27.408 61.088 61.088v75.152c0 33.68-27.4 61.088-61.088 61.088z m0-165.328a29.12 29.12 0 0 0-29.096 29.088v75.152a29.12 29.12 0 0 0 29.096 29.088 29.12 29.12 0 0 0 29.088-29.088v-75.152a29.12 29.12 0 0 0-29.088-29.088z'
					fill='#006600'
				/>
				<path
					className='fill'
					d='M489.424 194.248c-82.736 0-152.912 53.496-177.992 127.76 0 10.376 80.208 18.792 179.168 18.792 98.96 0 176.816-8.416 176.816-18.792-25.08-74.264-95.272-127.76-177.992-127.76z'
					fill='#00aa00'
				/>
				<path
					className='stroke'
					d='M490.6 356.8c-195.168 0-195.168-22.632-195.168-34.792 0-1.744 0.288-3.472 0.84-5.12 28-82.928 105.624-138.64 193.152-138.64s165.152 55.712 193.152 138.64c0.552 1.648 0.84 3.376 0.84 5.12 0 12.152 0 34.792-192.816 34.792z m-159.144-42.408c22.6 5.016 80.272 10.408 159.144 10.408 80.752 0 135.28-5.424 156.832-10.304a171.744 171.744 0 0 0-158.008-104.24 171.72 171.72 0 0 0-157.968 104.136z'
					fill='#006600'
				/>
				<path
					className='stroke'
					d='M391.728 126.616a45.088 45.088 0 0 1 45.088 45.088v32.416a187.584 187.584 0 0 1 60.128-9.864c1.368 0 2.696 0.136 4.048 0.168 11.08-34.952 43.76-60.28 82.376-60.28 47.736 0 86.432 38.688 86.432 86.424v73.064c42.664 10.184 67.632 24.192 67.632 39.664v105.208c0 1.176-0.184 2.352-0.48 3.504l-59.648 436.136c0 31.128-40.92 56.36-184.12 56.36s-184.12-25.232-184.12-56.36l-59.64-434.2v-112c0-17.4 31.112-31.6 83.96-41.944a188.472 188.472 0 0 1 17-25.136 44.92 44.92 0 0 1-3.752-17.984v-75.152a45.096 45.096 0 0 1 45.096-45.112m0-32c-42.504 0-77.096 34.576-77.096 77.088v75.152c0 3.848 0.28 7.648 0.84 11.4-0.728 1.064-1.448 2.136-2.152 3.208-47.288 10.472-95.896 28.624-95.896 70.464v112c0 1.456 0.096 2.912 0.296 4.352l59.392 432.368c0.784 21.792 12.112 50.272 59.408 67.424 34.192 12.4 85.44 18.424 156.664 18.424s122.472-6.024 156.664-18.424c47.304-17.16 58.632-45.64 59.408-67.432l59.216-432.976a45.12 45.12 0 0 0 0.952-9.168V333.28c0-36.352-38.552-54.824-67.632-64.12v-48.608c0-65.296-53.128-118.424-118.432-118.424a117.92 117.92 0 0 0-103.496 60.776c-3.824 0.296-7.632 0.696-11.424 1.184-3.832-38.944-36.776-69.472-76.712-69.472z'
					fill='#006600'
				/>
			</svg>
		</div>
	);
}
