import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useAppSelector } from '../../reduxHook';

interface ICarousel {
	onImageChange: (index: number) => void;
}

const Carousel: React.FC<ICarousel> = ({ onImageChange }) => {
	var settings = {
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		beforeChange: (oldIndex: number, newIndex: number) => {
			onImageChange(newIndex);
		},
	};
	const { data } = useAppSelector(state => state.appManager);
	return data.bannerIds.length ? ( // ???
		<Slider {...settings}>
			{data.bannerIds.map((bannerId, index) => (
				<img
					key={bannerId}
					src={`https://drive.google.com/thumbnail?id=${bannerId}&sz=w1000`}
					width='340'
					alt={`${index}`}
				/>
			))}
		</Slider>
	) : (
		<></>
	);
};

export default Carousel;
