import { memo, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { resetCart, resetOrderStatus } from '../../redux/appManager';
import { useAppDispatch, useAppSelector } from '../../reduxHook';
import { ICartItem } from '../Store/storeManager';
import './payment.scss';

interface Time {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
}

export default function Payment() {
	const [time, setTime] = useState<Time>();
	const [isTimeUp, setIsTimeUp] = useState(false);
	const waitingTime = 600000;
	const { clientData } = useAppSelector(state => state.storeManager);
	const { cart, dataStatus } = useAppSelector(state => state.appManager);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const paymentCart = useRef(cart);
	const total = useRef(
		cart.reduce((acc, item) => acc + (item.product.promo || item.product.price) * item.quantity, 0),
	);

	const onPaymentClick = async () => {
		const timeStamp = Date.now();
		try {
			// const paymentData = await createPayment({
			// 	amount: total.current,
			// 	orderId: timeStamp.toString(),
			// 	description: 'Оплата заказа #' + timeStamp,
			// });
			// if (paymentData.status !== 200) {
			// 	throw new Error('fail');
			// }
			// добавляем paymentData.data.id в данные заказа в таблице

			// window.location.replace(paymentData.data.confirmation.confirmation_url);
			// window.location.replace('https://pro-cocktail.ru?1=1');
			window.location.replace('https://pro-cocktail.ru/cart');
		} catch (error) {
			console.log('fail');
		}
	};

	useEffect(() => {
		(dataStatus === 'loading' || dataStatus === 'error') && navigate('/', { replace: true });
	}, [dataStatus, navigate]);

	useEffect(() => {
		dispatch(resetOrderStatus());
		dispatch(resetCart());
	}, [dispatch]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const deadline = new Date().setTime(new Date().getTime() + waitingTime + 900);
		setTime({
			days: Math.floor(waitingTime / (1000 * 24 * 60 * 60)),
			hours: Math.floor((waitingTime % (1000 * 24 * 60 * 60)) / (1000 * 60 * 60)),
			minutes: Math.floor((waitingTime % (1000 * 60 * 60)) / (1000 * 60)),
			seconds: Math.floor((waitingTime % (1000 * 60)) / 1000),
		});

		let timer = setInterval(() => {
			const now = new Date().getTime();
			const distance = deadline - now;

			if (distance < 1000) {
				clearInterval(timer);
				setIsTimeUp(true);
				return;
			}
			setTime({
				days: Math.floor(distance / (1000 * 24 * 60 * 60)),
				hours: Math.floor((distance % (1000 * 24 * 60 * 60)) / (1000 * 60 * 60)),
				minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
				seconds: Math.floor((distance % (1000 * 60)) / 1000),
			});
		}, 1000);

		return () => clearInterval(timer);
	}, []);

	const formatNumber = (num: number | undefined) => {
		return num?.toString().padStart(2, '0');
	};

	if (isTimeUp) {
		return <div className='payment-container'>Payment timed out</div>;
	}

	if (dataStatus === 'loading') return <Loader className='content__loading' />;

	return (
		<div className='payment-container'>
			{/* {formatNumber(time?.days)?.[0]}
			{formatNumber(time?.days)?.[1]}
			{formatNumber(time?.hours)?.[0]}
			{formatNumber(time?.hours)?.[1]} */}
			<div className='payment-container__float'>
				<div className='payment-container__waiting-time'>
					{formatNumber(time?.minutes)?.[0]}
					{formatNumber(time?.minutes)?.[1]}:{formatNumber(time?.seconds)?.[0]}
					{formatNumber(time?.seconds)?.[1]}
				</div>
				<h3>Ваш заказ забронирован!</h3>
				<p>
					Во избежание корректировки наличия, выбранных вами позиций, оплатите заказ в течение 10
					минут.
				</p>
			</div>
			<div className='payment-wrapper'>
				<div style={{ height: 130 }}></div>
				<div className='payment-wrapper__cart-info'>
					<PaymentCartBlock cart={paymentCart.current} />
					<div className='divider'></div>
					<div className='payment-wrapper__total'>Итого: {total.current} &#8381;</div>
				</div>
				{clientData?.deliveryMethod && (
					<div className='payment-wrapper__client-info'>
						<div className='payment-wrapper__client-info__name'>
							{clientData.name} {clientData.lastName}
						</div>
						<div className='payment-wrapper__client-info__phone'>{clientData.phone}</div>
						<div className='payment-wrapper__client-info__delivery'>
							{clientData.deliveryMethod === 'byPost' ? 'Почта России' : 'пункт СДЭК по адресу:'}
						</div>
						<div className='payment-wrapper__client-info__city'>
							{clientData.index}
							{clientData.index.length > 0 && ', '}
							{clientData.city}
						</div>
						<div className='payment-wrapper__client-info__address'>
							{clientData.street}, {clientData.home}
							{clientData.litera.length > 0 && '/'}
							{clientData.litera}
							{clientData.flat.length > 0 && clientData.flat !== '-' && '-'}
							{clientData.flat !== '-' && clientData.flat}
						</div>
					</div>
				)}
				<div className='payment-wrapper__action'>
					{clientData?.paymentMethod === 'online' ? (
						<button className='button-filled' type='button' onClick={onPaymentClick}>
							Перейти в платёжную систему
						</button>
					) : (
						<div>Сбербанк Елизавета Викторовна Б.</div>
					)}
				</div>
			</div>
		</div>
	);
}

interface PaymentBlockProps {
	cart: Array<ICartItem>;
}

const PaymentCartBlock: React.FC<PaymentBlockProps> = memo(({ cart }) => {
	return cart.map((cartItem, index) => {
		return cartItem ? (
			<div key={cartItem.product.id}>
				<p>{index + 1 + '. ' + cartItem.product.title}</p>
				<div className='payment-wrapper__formula'>
					<p>
						{cartItem.product.promo || cartItem.product.price}{' '}
						<span className='payment-wrapper__sign'> х </span>
						<span className='payment-wrapper__quantity'>{cartItem.quantity}</span>
						<span className='payment-wrapper__sign'> = </span>
						<span className='payment-wrapper__multi-price'>
							{(cartItem.product.promo || cartItem.product.price) * cartItem.quantity} &#8381;
						</span>
					</p>
				</div>
			</div>
		) : (
			<></>
		);
	});
});
