export default function RemoveIcon() {
	return (
		<svg width='18px' height='18px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
			<g id='minus'>
				<g>
					<line
						fill='none'
						stroke='white'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='2'
						x1='5'
						x2='19'
						y1='12'
						y2='12'
					/>
				</g>
			</g>
		</svg>
	);
}
