import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddIcon from '../../components/AddIcon';
import BackIcon from '../../components/BackIcon';
import Button from '../../components/Button/Button';
import DeleteIcon from '../../components/DeleteIcon';
import Loader from '../../components/Loader';
import RemoveIcon from '../../components/RemoveIcon';
import { setCart, VisitorStatus } from '../../redux/appManager';
import { useAppSelector } from '../../reduxHook';
import addProductToCart from '../../utils/AddProductToCart';
import removeProductFromCart from '../../utils/RemoveProductFromCart';
import { ICartItem, IProduct } from '../Store/storeManager';
import './cart.scss';

export default function Cart() {
	const { cartBackPath } = useAppSelector(state => state.storeManager);
	const { cart, data, dataStatus } = useAppSelector(state => state.appManager);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const total = useMemo(() => {
		return cart.reduce(
			(acc, item) => acc + (item.product.promo || item.product.price) * item.quantity,
			0,
		);
	}, [cart]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleAddProductToCart = (product: IProduct) => {
		dispatch(setCart(addProductToCart(true, product, cart)));
	};

	const handleRemoveProductFromCart = (product: IProduct) => {
		dispatch(setCart(removeProductFromCart(true, product, cart)));
	};

	if (dataStatus === 'loading') return <Loader className='content__loading' />;
	if (dataStatus === 'error') return <div className='content__disconnect'>Нет соединения!</div>;

	if (cart.length === 0) {
		return (
			<div style={{ paddingTop: 60, paddingBottom: 20 }}>
				<div className='cart-product-item__total'>Корзина пуста</div>
				<div className='cart-product-item__button'>
					<Button
						onClick={() => {
							navigate('/', { replace: true });
						}}
						className='button'>
						Вернуться к продуктам
					</Button>
				</div>
			</div>
		);
	}

	return (
		<div className='cart-container'>
			<BackIcon onIconClick={() => navigate(`${cartBackPath}`, { replace: true })} />
			{cart.map(cartItem => {
				return (
					<CartProductBlock
						key={cartItem.product.id}
						cartItem={cartItem}
						handleRemoveProductFromCart={handleRemoveProductFromCart}
						handleAddProductToCart={handleAddProductToCart}
						onDeleteClick={() =>
							dispatch(setCart(cart.filter(item => item.product.id !== cartItem.product.id)))
						}
					/>
				);
			})}
			<div className='cart-product-item__total'>Всего: {total} &#8381;</div>
			<div className='cart-product-item__button'>
				<Button
					onClick={() => {
						data.visitorStatus === VisitorStatus.USER
							? navigate('/order', { replace: true })
							: navigate('/welcome', { replace: true });
					}}
					className='button-filled'>
					Оформить заказ
				</Button>
			</div>
		</div>
	);
}

interface CartProductBlockProps {
	cartItem: ICartItem;
	handleRemoveProductFromCart: (product: IProduct) => void;
	handleAddProductToCart: (product: IProduct) => void;
	onDeleteClick: () => void;
}

const CartProductBlock: React.FC<CartProductBlockProps> = ({
	cartItem,
	handleRemoveProductFromCart,
	handleAddProductToCart,
	onDeleteClick,
}) => {
	return (
		<div>
			<div className='cart-product-item__title'>{cartItem.product.title}</div>
			<div className='cart-product-item__formula'>
				<p>
					<span className='cart-product-item__price'>
						{cartItem.product.promo || cartItem.product.price}
					</span>
					<span className='cart-product-item__sign'>х</span>
					<span className='cart-product-item__quantity'>{cartItem.quantity}</span>
					<span className='cart-product-item__sign'>=</span>
					<span className='cart-product-item__multi-price'>
						{(cartItem.product.promo || cartItem.product.price) * cartItem.quantity} &#8381;
					</span>
				</p>
			</div>
			<div className='cart-product-item__actions'>
				<DeleteIcon onIconClick={onDeleteClick} />
				<div className='cart-product-item__buttons'>
					<Button
						onClick={() => handleRemoveProductFromCart(cartItem.product)}
						className='button'
						disabled={cartItem.quantity <= 1}>
						<RemoveIcon />
					</Button>
					<Button onClick={() => handleAddProductToCart(cartItem.product)} className='button'>
						<AddIcon />
					</Button>
				</div>
			</div>
			<div className='divider'></div>
		</div>
	);
};
