export default function LightModeIcon({ ...attributes }) {
	return (
		<div className={attributes.className} {...attributes}>
			<svg
				fill='#faf73f'
				height='28px'
				width='28px'
				version='1.1'
				id='light'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 512 512'>
				<g>
					<g>
						<path d='M256,0c-9.223,0-16.696,7.473-16.696,16.696v33.391c0,9.223,7.473,16.696,16.696,16.696s16.696-7.473,16.696-16.696    V16.696C272.696,7.473,265.223,0,256,0z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M256,445.217c-9.223,0-16.696,7.473-16.696,16.696v33.391c0,9.223,7.473,16.696,16.696,16.696s16.696-7.473,16.696-16.696    v-33.391C272.696,452.69,265.223,445.217,256,445.217z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M50.087,239.304H16.696C7.473,239.304,0,246.777,0,256s7.473,16.696,16.696,16.696h33.391    c9.223,0,16.696-7.473,16.696-16.696S59.31,239.304,50.087,239.304z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M495.304,239.304h-33.391c-9.223,0-16.696,7.473-16.696,16.696s7.473,16.696,16.696,16.696h33.391    c9.217,0,16.696-7.473,16.696-16.696S504.521,239.304,495.304,239.304z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M92.129,350.609c-4.604-7.989-14.81-10.723-22.804-6.108l-28.918,16.696c-7.983,4.608-10.722,14.82-6.108,22.804    c4.622,8.011,14.831,10.722,22.804,6.108l28.918-16.696C94.004,368.804,96.744,358.593,92.129,350.609z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M477.7,128c-4.604-7.978-14.805-10.729-22.804-6.108l-28.918,16.696c-7.984,4.609-10.723,14.82-6.108,22.804    c4.622,8.011,14.83,10.722,22.804,6.108l28.918-16.696C479.576,146.195,482.315,135.984,477.7,128z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M161.391,419.87c-7.978-4.619-18.196-1.864-22.804,6.108l-16.696,28.918c-4.615,7.984-1.875,18.196,6.108,22.804    c7.984,4.619,18.19,1.879,22.804-6.108l16.696-28.918C172.114,434.69,169.375,424.478,161.391,419.87z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M384,34.3c-7.978-4.609-18.19-1.864-22.804,6.108L344.5,69.326c-4.615,7.984-1.875,18.196,6.108,22.804    c7.984,4.619,18.19,1.878,22.804-6.108l16.696-28.918C394.723,49.12,391.984,38.908,384,34.3z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M390.108,454.898l-16.696-28.918c-4.604-7.989-14.793-10.729-22.804-6.108c-7.984,4.609-10.723,14.82-6.108,22.804    l16.696,28.918c4.622,8.011,14.83,10.721,22.804,6.108C391.984,473.092,394.723,462.881,390.108,454.898z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M167.5,69.326l-16.696-28.918C146.201,32.424,136.005,29.69,128,34.3c-7.984,4.609-10.723,14.82-6.108,22.804    l16.696,28.918c4.622,8.011,14.831,10.722,22.804,6.108C169.375,87.521,172.114,77.31,167.5,69.326z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M471.592,361.196L442.674,344.5c-7.984-4.615-18.196-1.864-22.804,6.108c-4.615,7.984-1.875,18.196,6.108,22.804    l28.918,16.696c7.984,4.619,18.19,1.878,22.804-6.108C482.315,376.016,479.576,365.804,471.592,361.196z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M86.022,138.587l-28.918-16.696c-7.989-4.619-18.19-1.87-22.804,6.108c-4.615,7.984-1.875,18.196,6.108,22.804    L69.326,167.5c7.984,4.619,18.192,1.878,22.804-6.108C96.745,153.407,94.005,143.195,86.022,138.587z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M256,100.174c-85.924,0-155.826,69.902-155.826,155.826S170.076,411.826,256,411.826S411.826,341.924,411.826,256    S341.924,100.174,256,100.174z' />
					</g>
				</g>
			</svg>
		</div>
	);
}
