import { configureStore } from '@reduxjs/toolkit';
import storeManager from '../pages/Store/storeManager';
import appManager from './appManager';

export const store = configureStore({
	reducer: {
		appManager,
		storeManager,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
