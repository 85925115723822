import AddIcon from '../AddIcon';
import Button from '../Button/Button';
import '../Button/button.scss';
import RemoveIcon from '../RemoveIcon';

interface AddRemoveBlockProps {
	className: string;
	productAmountInCart: number;
	isEnded: boolean;
	handleRemoveProductFromCart: () => void;
	handleAddProductToCart: () => void;
}

const AddRemoveBlock: React.FC<AddRemoveBlockProps> = ({
	className,
	productAmountInCart,
	isEnded = false,
	handleRemoveProductFromCart,
	handleAddProductToCart,
}) => {
	return (
		<div className={className}>
			<Button
				onClick={() => handleRemoveProductFromCart()}
				className='button'
				disabled={productAmountInCart <= 0 || isEnded}>
				<RemoveIcon />
			</Button>
			<Button onClick={() => handleAddProductToCart()} className='button' disabled={isEnded}>
				{/* disabled={product.quantityInStock <= productAmountInCart} */}
				<AddIcon />
			</Button>
		</div>
	);
};

export default AddRemoveBlock;
