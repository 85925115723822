export default function DarkModeIcon({ ...attributes }) {
	return (
		<div className={attributes.className} {...attributes}>
			{/* <svg width='24px' height='24px' xmlns='http://www.w3.org/2000/svg' viewBox='0 -2 15 25'>
				<path
					d='M5,0A10,10,0,0,0,0,1.35,10,10,0,0,1,5,10a10,10,0,0,1-5,8.65A10,10,0,1,0,5,0Z'
					fill='black'
				/>
			</svg> */}

			<svg
				width='28px'
				height='28px'
				viewBox='0 0 24 24'
				version='1.1'
				xmlns='http://www.w3.org/2000/svg'>
				<g id='moon' stroke='none' strokeWidth='0' fill='none' fillRule='evenodd'>
					<g id='Weather' transform='translate(-528.000000, -48.000000)' fillRule='nonzero'>
						<g id='moon_stars_fill' transform='translate(528.000000, 48.000000)'>
							<path
								d='M12.4769,4.54557 C12.1415,3.79855 12.7837,2.99779 13.5739,3.13729 C17.7941,3.88225 21,7.5657 21,12 C21,16.9705 16.9705,21 12,21 C7.5657,21 3.88225,17.7941 3.13729,13.5739 C2.99778,12.7836 3.79861,12.1416 4.54557,12.4769 C5.29352,12.8127 6.12352,13 6.99996,13 C10.3137,13 13,10.3137 13,6.99996 C13,6.12352 12.8127,5.29352 12.4769,4.54557 Z M5.56482,7.71573 L5.62857,7.85506 L5.62857,7.85506 C5.90134,8.41936 6.33105,8.89305 6.86601,9.21934 L6.96534,9.27755 C7.0115,9.30351 7.0115,9.36997 6.96534,9.39593 L6.86601,9.45414 C6.33105,9.78043 5.90134,10.2541 5.62857,10.8184 L5.56482,10.9577 L5.56482,10.9577 C5.53984,11.0139 5.46007,11.0139 5.4351,10.9577 L5.37134,10.8184 L5.37134,10.8184 C5.09857,10.2541 4.66887,9.78043 4.13391,9.45414 L4.03457,9.39593 L4.03457,9.39593 C3.98842,9.36997 3.98842,9.30351 4.03457,9.27755 L4.13391,9.21934 L4.13391,9.21934 C4.66887,8.89305 5.09857,8.41936 5.37134,7.85506 L5.4351,7.71573 L5.4351,7.71573 C5.46007,7.65953 5.53984,7.65953 5.56482,7.71573 Z M8.39662,2.85738 C8.43638,2.76792 8.56335,2.76792 8.6031,2.85738 L8.70458,3.07914 L8.70458,3.07914 C9.13876,3.97736 9.82274,4.73135 10.6743,5.25072 L10.8324,5.34337 L10.8324,5.34337 C10.9058,5.38469 10.9058,5.49048 10.8324,5.5318 L10.6743,5.62445 L10.6743,5.62445 C9.82274,6.14382 9.13876,6.89781 8.70458,7.79603 C8.66919,7.86924 8.63613,7.94349 8.6031,8.0178 C8.56335,8.10725 8.43638,8.10725 8.39662,8.0178 L8.29515,7.79603 L8.29515,7.79603 C7.86097,6.89781 7.17699,6.14382 6.32548,5.62445 L6.16736,5.5318 L6.16736,5.5318 C6.09389,5.49048 6.09389,5.38469 6.16736,5.34337 L6.32548,5.25072 L6.32548,5.25072 C7.17699,4.73135 7.86097,3.97736 8.29515,3.07914 L8.39662,2.85738 L8.39662,2.85738 Z'
								id='dark'
								fill='#09244B'></path>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
}
